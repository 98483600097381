import Header from "../components/Header";
import CourseRegForm from "../components/CourseRegForm";
import styles from "./CourseRegistrationPage.module.css";
const CourseRegistrationPage = () => {
  return (
    <div className={styles.courseregistrationpage}>
      <Header hamburger="/hamburger1.svg" />
      <div className={styles.registrationsection}>
        <CourseRegForm />
      </div>
      <div className={styles.footer}>
        <div className={styles.copyrightInformation}>
          <b>PFPA</b>
          <span
            className={styles.pacificBanking}
          >{` © 2024, Pacific Banking & Finance Association.`}</span>
        </div>
        <div className={styles.footerLinks}>
          <button className={styles.membership}>Membership</button>
          <button className={styles.membership}>Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistrationPage;
