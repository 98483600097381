import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SummerCampDetailContainer.module.css";
const SummerCampDetailContainer = () => {
  const navigate = useNavigate();

  const onRegistrationButtonClick = useCallback(() => {
    navigate("/courseregistrationpage");
  }, [navigate]);

  return (
    <div className={styles.sumercampdetail}>
      <div className={styles.timesection}>
        <b className={styles.time}>时间</b>
        <div className={styles.thGradeOr}>June 3 – June 7, 2024</div>
      </div>
      <div className={styles.timesection}>
        <b className={styles.time}>Ages:</b>
        <div className={styles.thGradeOr}>High School or College Student</div>
      </div>
      <div className={styles.pricesection}>
        <b className={styles.pfpaReservesThe}>
          Discounted Price $599 / Participant for Early Enrollment Before May 31 2024
        </b>
        <b className={styles.heading41}>
          Regular Price $699 / Participant for Enrollment After June 01 2024
        </b>

      </div>
      <div className={styles.timesection}>
        <b className={styles.time}>地点/Location</b>
        <div className={styles.thGradeOr}>
          <p
            className={styles.northPointCenter}
          >{`100 North Point Center East, Suite 200
`}</p>
          <p className={styles.northPointCenter}>Alpharetta, Georgia 30022</p>
        </div>
      </div>
      <button
        className={styles.registrationbutton}
        onClick={onRegistrationButtonClick}
      >
        <div className={styles.registrationbuttonInner}>
          <div className={styles.joinUsWrapper}>
            <b className={styles.joinUs}>Registration</b>
          </div>
        </div>
      </button>
      <div className={styles.disclaimerframe}>
        <div
          className={styles.pfpaReservesThe}
        >{`* PFPA reserves the right to cancel the summer camp program due to insufficient enrolment or for other unforeseen circumstances. In the event of a cancellation, participants will receive a full refund.  `}</div>
      </div>
    </div>
  );
};

export default SummerCampDetailContainer;
