import Header from "../components/Header";
import PBFAHeroContainer from "../components/PBFAHeroContainer";
import AboutUsContainer from "../components/AboutUsContainer";
import AboutUsContainerChinese from "../components/AboutUsContainerChinese";
import SummerCampDetailContainer from "../components/SummerCampDetailContainer";
import RiskModelContainer from "../components/RiskModelContainer";
import styles from "./LandingPage.module.css";
const LandingPage = () => {
  return (
    <div className={styles.landingpage}>
      <Header hamburger="/hamburger.svg" />

      <AboutUsContainer />
      <AboutUsContainerChinese />
      <div className={styles.summercampsection}>
        <div className={styles.memberbenefittitle}>
          <div className={styles.summerCampBenefit}>Summer Camp Benefit</div>
          <div className={styles.thisPfpaSummerContainer}>
            <p className={styles.thisPfpaSummer}>
              This PFPA summer camp courses is designed and taught by real-world
              experts in financial industry including Bank executives,
              Investment specialists, Venture capitalists, Professional risk
              managers, Mathematical modelers and Technology wizards.
            </p>
            <p className={styles.p}>
              欢迎参加PFPA 2024夏令营,这是一个面向中学生和大学生的难得机会，让他们深入了解金融世界。由金融专业人士协会主办的这个夏令营，以其实践知识和亲身体验的结合，成为通往金融行业的独特通道。
            </p>
          </div>
        </div>
        <a
          className={styles.courseDurationOneContainer}
          href="https://goo.gl/maps/WemnYri9JGfwWaHK8"
          target="_blank"
        >
          <p className={styles.p}>Course Duration: One week</p>
          <p className={styles.p}>时间长度：一周</p>
        </a>
        <SummerCampDetailContainer />
      </div>
      <RiskModelContainer />
      <div className={styles.footer}>
        <div className={styles.copyrightInformation}>
          <b>PFPA</b>
          <span
            className={styles.pacificBanking}
          >{` © 2024, Pacific Banking & Finance Association.`}</span>
        </div>
        <div className={styles.footerLinks}>
          <button className={styles.membership}>Membership</button>
          <button className={styles.membership}>Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
