import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DrawerMenu from "./DrawerMenu";
import PortalDrawer from "./PortalDrawer";
import styles from "./Header.module.css";
const Header = ({ hamburger }) => {
  const navigate = useNavigate();
  const [isDrawerMenuOpen, setDrawerMenuOpen] = useState(false);

  const onMembershipClick = useCallback(() => {
    window.open("https://pfpa-financial.com/become-member/");
  }, []);

  const onRegistrationClick = useCallback(() => {
    navigate("/courseregistrationpage");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    window.open("https://pfpa-financial.com/about/");
  }, []);

  const openDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(true);
  }, []);

  const closeDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(false);
  }, []);

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.headerElementsRow}>
          <img
            className={styles.pfpaLogoSmall1Icon}
            alt=""
            src="/pfpalogosmall-1@2x.png"
          />
          <div className={styles.headerMenuOptionsRow}>
            <div className={styles.headerLinks}>
              <button className={styles.home} onClick={onHomeClick}>Home</button>
              <button className={styles.membership} onClick={onMembershipClick}>
                Membership
              </button>
              <button
                className={styles.registration}
                onClick={onRegistrationClick}
              >
                Registration
              </button>
              <button className={styles.aboutUs} onClick={onAboutUsClick}>
                About Us
              </button>
            </div>
            <div
              className={styles.headerHamburgerMenu}
              onClick={openDrawerMenu}
            >
              <div className={styles.hamburgerBackground} />
              <img className={styles.hamburgerIcon} alt="" src={hamburger} />
            </div>
            <div className={styles.seperator} />
            <button className={styles.headerSearchIcon}>
              <img className={styles.vectorIcon} alt="" src="/vector.svg" />
            </button>
          </div>
        </div>
      </div>
      {isDrawerMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeDrawerMenu}
        >
          <DrawerMenu onClose={closeDrawerMenu} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Header;
