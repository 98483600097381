import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AboutUsContainer.module.css";
const AboutUsContainer = () => {
  const navigate = useNavigate();

  const onRegistrationButtonClick = useCallback(() => {
    navigate("/courseregistrationpage");
  }, [navigate]);

  return (
    <div className={styles.aboutUs}>
      <img
        className={styles.aboutusimagecontainerIcon}
        alt=""
        src="/aboutusimagecontainer@2x.png"
      />
      <div className={styles.sumercampintrosection}>
        <div className={styles.introcontent}>
          <b className={styles.camptitle}>
            <p className={styles.pfpaSummerCamp}>PFPA Summer Camp</p>
          </b>
          <button
            className={styles.registrationbutton}
            onClick={onRegistrationButtonClick}
          >
            <div className={styles.registrationbuttonInner}>
              <div className={styles.joinUsWrapper}>
                <b className={styles.joinUs}>Registration</b>
              </div>
            </div>
          </button>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              Welcome to the PFPA 2024 Summer Camp, a unique opportunity for high school and college students to dive into the world of finance. Organized by the Financial Professionals Association, this summer camp stands out as a gateway to the industry, offering an unparalleled blend of practical knowledge and hands-on experience.
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              At the heart of our program are the experts from various banks and financial institutions, who bring their frontline experience to the classroom. They will share real-world insights and stories, covering all aspects of finance and financial technology, making this camp an exceptional learning experience that cannot be replicated elsewhere.
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              This summer camp is more than just an educational venture; it's an immersive journey into the finance sector. Students will gain a deep understanding of the industry, from its fundamental concepts to the latest trends in financial technology. They will also have the rare opportunity to meet and learn from corporate experts and executives, providing an insider's view of the financial world.
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              In addition to theoretical learning, our camp includes visits to banks and other financial institutions, allowing students to witness the inner workings of the finance industry first-hand. These experiences are invaluable for those considering a future in this field.
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              Moreover, PFPA 2024 Summer Camp is committed to fostering future leaders in finance. We identify and recommend outstanding participants for further advanced studies, ensuring that the next generation of financial professionals is well-equipped to excel in this dynamic and challenging industry.
            </div>
          </div>
          <div className={styles.welcomeToThe}>
            <p className={styles.pfpaSummerCamp}>
              Join us this summer for an unforgettable journey into the world of finance. Embrace the opportunity to learn, explore, and connect with the industry's best at the PFPA 2024 Summer Camp.
            </p>
          </div>
        </div>
        <button
          className={styles.registrationbutton}
          onClick={onRegistrationButtonClick}
        >
          <div className={styles.registrationbuttonInner}>
            <div className={styles.joinUsWrapper}>
              <b className={styles.joinUs}>Registration</b>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AboutUsContainer;
