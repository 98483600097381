import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AboutUsContainer.module.css";
const AboutUsContainerChinese = () => {
  const navigate = useNavigate();

  const onRegistrationButtonClick = useCallback(() => {
    navigate("/courseregistrationpage");
  }, [navigate]);

  return (
    <div className={styles.aboutUs}>
      <img
        className={styles.aboutusimagecontainerIcon}
        alt=""
        src="/shanghai_2.png"
      />
      <div className={styles.sumercampintrosection}>
        <div className={styles.introcontent}>
          <b className={styles.camptitle}>
            <p className={styles.pfpaSummerCamp}>金融协会夏令营</p>
          </b>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              欢迎参加PFPA 2024夏令营，这是一个面向中学生和大学生的难得机会，让他们深入了解金融世界。由金融专业人士协会主办的这个夏令营，以其实践知识和亲身体验的结合，成为通往金融行业的独特通道。
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              我们的课程核心是各银行和金融机构的一线专家，他们将把自己的实战经验带入教室。他们将分享真实的见解和故事，涵盖金融和金融科技的各个方面，使这个夏令营成为一个无法复制的特殊学习体验。
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              这个夏令营不仅仅是教育活动；它是深入金融领域的沉浸式之旅。学生们将深入了解该行业，从基本概念到金融科技的最新趋势。他们还将有机会与企业专家和高管会面和学习，提供金融世界的内部视角。
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              除了理论学习，我们的夏令营还包括参观银行和其他金融机构，让学生亲眼见证金融行业的内部运作。对于那些考虑未来从事这个领域的学生来说，这些经历是无价的。
            </div>
          </div>
          <div className={styles.paragraph1}>
            <div className={styles.welcomeToThe}>
              此外，PFPA 2024夏令营致力于培养金融领域的未来领导者。我们将识别并推荐表现杰出的参与者进行进一步的深造，确保下一代金融专业人士能够在这个充满活力和挑战的行业中表现出色。
            </div>
          </div>

          <div className={styles.welcomeToThe}>
            <p className={styles.pfpaSummerCamp}>
              加入我们的夏令营，在这个夏天享受一次难忘的金融世界之旅。抓住机会，在PFPA 2024夏令营中学习、探索，并与行业最佳人才建立联系。
            </p>
          </div>
        </div>
        <button
          className={styles.registrationbutton}
          onClick={onRegistrationButtonClick}
        >
          <div className={styles.registrationbuttonInner}>
            <div className={styles.joinUsWrapper}>
              <b className={styles.joinUs}>Registration</b>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AboutUsContainerChinese;
