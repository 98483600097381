/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourseRegistration = /* GraphQL */ `
  mutation CreateCourseRegistration(
    $input: CreateCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    createCourseRegistration(input: $input, condition: $condition) {
      id
      lastName
      firstName
      parentName
      email
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      notes
      checked
      type
      confirmationId
      zelleConfirmationCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCourseRegistration = /* GraphQL */ `
  mutation UpdateCourseRegistration(
    $input: UpdateCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    updateCourseRegistration(input: $input, condition: $condition) {
      id
      lastName
      firstName
      parentName
      email
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      notes
      checked
      type
      confirmationId
      zelleConfirmationCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCourseRegistration = /* GraphQL */ `
  mutation DeleteCourseRegistration(
    $input: DeleteCourseRegistrationInput!
    $condition: ModelCourseRegistrationConditionInput
  ) {
    deleteCourseRegistration(input: $input, condition: $condition) {
      id
      lastName
      firstName
      parentName
      email
      phoneNumber
      addressLine1
      addressLine2
      city
      state
      zip
      notes
      checked
      type
      confirmationId
      zelleConfirmationCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
