import styles from "./RiskModelContainer.module.css";
const RiskModelContainer = () => {
  return (
    <div className={styles.coursesyllabus}>
      <div className={styles.discoverTheWorldHeader}>
        <div className={styles.discoverTheWorld}>Course Syllabus</div>
      </div>
      <div className={styles.list}>
        <div className={styles.item1}>
          <div className={styles.contentframe1}>
            <div className={styles.itemcontent1}>
              <div className={styles.numberframe1}>
                <b className={styles.b}>1</b>
              </div>
              <div className={styles.itemframe1}>
                <b className={styles.englishtitle1}>
                  Investment Banks: Products, and Quantitative methods
                </b>
                <div className={styles.englishcontent1}>
                  Investment bank operations and businesses, capital market
                  products, trading strategies and market analysis, research and
                  quantitative methods, financial derivative products developed
                  using model financial theory and rocket-science, etc.
                </div>
                <b className={styles.englishtitle1}>
                  投资银行业务，相关产品和量化方法
                </b>
                <div className={styles.englishcontent1}>
                  包括：投资银行的商务和运作模式，资本市场产品，交易策略，市场分析，研究和量化方法，基于现代金融理论和“火箭技术”的金融衍生产品，等等。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item1}>
          <div className={styles.contentframe1}>
            <div className={styles.itemcontent1}>
              <div className={styles.numberframe1}>
                <b className={styles.b}>2</b>
              </div>
              <div className={styles.itemframe1}>
                <b
                  className={styles.englishtitle1}
                >{`Commercial Banks: Businesses & Assets, and Risk Management`}</b>
                <div className={styles.englishcontent1}>
                  Bank businesses, deposit operations, lending operations, asset
                  and liability management, risk management and modeling,
                  Bank-run and financial crisis, bank regulations and
                  compliance, etc.
                </div>
                <b className={styles.englishtitle1}>
                  商业银行业务，资产，和风险管理
                </b>
                <div className={styles.englishcontent1}>
                  包括：商业银行业务，存款和贷款运营，资产和债务管理，风险管理和模型，银行挤兑，金融风险，银行监管和合规，等等。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item1}>
          <div className={styles.contentframe1}>
            <div className={styles.itemcontent1}>
              <div className={styles.numberframe1}>
                <b className={styles.b}>3</b>
              </div>
              <div className={styles.itemframe1}>
                <b className={styles.englishtitle1}>
                  Mathematical Modeling in Banks
                </b>
                <div className={styles.englishcontent1}>
                  Bank risk types and risk models, mathematical foundations and
                  modeling techniques, big data, machine learning and neural
                  network algorithm, cryptography and coding techniques, etc.
                </div>
                <b className={styles.englishtitle1}>银行的数学模型</b>
                <div className={styles.englishcontent1}>
                  包括：银行风险的类型和风险模型，数学基础和模型技术，大数据，机器学习，神经网络算法，密码学和加密技术，等等
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item1}>
          <div className={styles.contentframe1}>
            <div className={styles.itemcontent1}>
              <div className={styles.numberframe1}>
                <b className={styles.b}>4</b>
              </div>
              <div className={styles.itemframe1}>
                <b className={styles.englishtitle1}>FinTech</b>
                <div className={styles.englishcontent1}>
                  Blockchain technology, coins and tokens, non-traditional
                  banking businesses, p2p and bnpl, digital currency, Artificial
                  Intelligent technologies including AlphaGo, Large language
                  model and ChatGPT, Robotic Process Automations and
                  Robo-advisor, etc.
                </div>
                <b className={styles.englishtitle1}>金融科技</b>
                <div className={styles.englishcontent1}>
                  包括：区块链技术，数字货币，代币和令牌，非传统金融业务，p2p和先买后付业务，人工智能包括围棋AlphaGo,
                  大语言模型，ChatGPT，机器人流程自动化，智能理财，等等。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item1}>
          <div className={styles.contentframe1}>
            <div className={styles.itemcontent1}>
              <div className={styles.numberframe1}>
                <b className={styles.b}>5</b>
              </div>
              <div className={styles.itemframe1}>
                <b className={styles.englishtitle1}>Other financial Services</b>
                <div className={styles.englishcontent1}>
                  Insurance products and actuarial science, mutual fund and
                  hedge fund, tax accounting, financial accounting, venture
                  capital, financial advisors and agents, Anti-Money Laundering
                  and Counter Terrorism Financing, etc.
                </div>
                <b className={styles.englishtitle1}>其它金融服务</b>
                <div className={styles.englishcontent1}>
                  包括：保险产品，精算学，共同基金和对冲基金，税务会计和财务会计，风险投资，财务顾问和代理人，反洗钱和反恐融资，等等。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskModelContainer;
