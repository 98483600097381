import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import { Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Amplify, Auth, API } from 'aws-amplify';
import { createCourseRegistration } from "../graphql/mutations";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from "./CourseRegForm.module.css";
import { CLIENT_ID } from '../config/config'

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

import awsExports from '../aws-exports';
Amplify.configure(awsExports);

const defaultRegistrationValues = {
  lastName: "",
  firstName: "",
  parentName: "",
  phoneNumber: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  notes: "",
  checked: true
};

const validationSchema = yup.object().shape({
  firstName: yup.string().min(2).required("Required"),
  lastName: yup.string().min(2).required("Required"),
  //zelleConfirmationCode: yup.string().min(1).required("Required"),
  email: yup.string().email().required("Required"),
  state: yup.string()
    .min(2, "Must be exactly 2 char")
    .max(2, "Must be exactly 2 char").required("Required"),
  zip: yup.string().required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits")
});

const CourseRegForm = () => {
  const [pFPAMemberChecked, setPFPAMemberChecked] = useState(false)
  const [checked, setChecked] = React.useState(false);
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState("")
  const [openRegistered, setOpenRegistered] = useState(false);

  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [price, setPrice] = useState(599)
  const [confirmationId, setConfirmationId] = useState("");
  const [paymentComplete, setPaymentComplete] = useState(false)

  const navigate = useNavigate();

  const initialOptions = {
    "client-id": CLIENT_ID,
    "enable-funding": "paypal,card",
  };

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "PFPA Summer Camp Tuition",
          amount: {
            currency_code: "USD",
            value: price,
          },
        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer, purchase_units } = details;
      const confirmationId = purchase_units[0].payments.captures[0].id
      const paymentStatus = purchase_units[0].payments.captures[0].status;
      console.log("payer:", payer)
      //console.log("pu:", purchase_units)
      console.log(confirmationId, paymentStatus);
      if ("COMPLETED" === paymentStatus) {
        setConfirmationId(confirmationId);
        setPaymentComplete(true);
        setShow(false)
      }
      setSuccess(true);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      //alert("Payment successful!!");
      console.log('Order successful . Your order id is--', orderID, "confirmID:", confirmationId);
    }
  }, [success]);

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { isSubmitSuccessful, errors } } = useForm({
      resolver: yupResolver(validationSchema),
      defaultRegistrationValues
    });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultRegistrationValues);
    }
  }, [formState, reset]);

  const handleClose = () => {
    setOpenRegistered(false);
    navigate("/");
  };

  useEffect(() => {
    Auth.signIn(
      '44d4c2e6-c759-4778-a3f7-4b584d60c203',
      'pfpaCS1460!'
    ).then(user => setUser(user))
      .catch(err => console.log(err));
  }, []);

  const onSubmit = data => {
    //console.log(data);
    registerCourses(data)

  }
  const registerCourses = async (data) => {

    const RegEx = /^(\+)|\D/g
    const cleanedPhone = `+${phone.replace(RegEx, '')}`;
    //console.log(cleanedPhone);

    try {
      const lastName = data.lastName;
      const firstName = data.firstName;
      const parentName = data.parentName;
      const email = data.email;
      const addressLine1 = data.addressLine1;
      const addressLine2 = data.addressLine2;
      const city = data.city;
      const state = data.state;
      const zip = data.zip;
      const notes = data.notes;
      const zelleConfirmationCode = data.zelleConfirmationCode

      const registrationInfo = {
        lastName, firstName, parentName, email,
        addressLine1, addressLine2, city, zip, notes, checked,
        state: state.toUpperCase(),
        id: uuid(),
        phoneNumber: cleanedPhone,
        type: 'CourseRegistration',
        confirmationId,
        zelleConfirmationCode
      };
      //console.log('input=', registrationInfo)

      await API.graphql({
        query: createCourseRegistration,
        variables: { input: registrationInfo },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

    } catch (err) {
      console.log('error: ', err);
    }
    setOpenRegistered(true);
    setPhone("");
    setChecked(false);
    setConfirmationId("")
    setPaymentComplete(false)
    //navigate("/");
  }

  const handleCheckbox1Change = (event) => {
    setChecked(event.target.checked);
  };
  const handlePFPACheckboxChange = (event) => {
    const isPFPAMember = event.target.checked
    setPFPAMemberChecked(isPFPAMember);
    if (isPFPAMember) {
      setPrice(549)
    } else {
      setPrice(599)
    }
  };
  const handlePhoneInputChange = value => {
    setPhone(value);
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <form className={styles.registrationform} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.courseformtitle}>
          <b className={styles.pfpaSummerCamp}>PFPA Summer Camp Registration is Closed</b>
        </div>
        <div className={styles.courseformframe}>
          <div className={styles.participantName}>Participant Name:</div>
          <div className={styles.nameframe}>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="First Name"
                placeholder="First Name"
                size="medium"
                margin="none"
                helperText={errors.firstName?.message}
                error={!!errors.firstName?.message}
                {...register("firstName")}
              />
            </div>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="Last Name"
                placeholder="Last Name"
                size="medium"
                margin="none"
                helperText={errors.lastName?.message}
                error={!!errors.lastName?.message}
                {...register("lastName")}
              />
            </div>
          </div>
          <div className={styles.pleaseFillThe}>Guardian/Parent Full Name:</div>
          <div className={styles.parentnameframe}>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="Parent Full Name"
                placeholder="Parent Full Name"
                size="medium"
                margin="none"
                {...register("parentName")}
              />
            </div>
          </div>
          <div className={styles.parentnameframe}>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type={"email"}
                label="Email"
                placeholder="Email"
                size="medium"
                margin="none"
                helperText={errors.email?.message}
                error={!!errors.email?.message}
                {...register("email")}
              />
            </div>
          </div>
          <div className={styles.phoneframe}>
            <div className={styles.phonenumbelabel}>
              <div className={styles.yourPhoneNumber}>Your Phone Number:</div>
            </div>
            <PhoneInput
              containerClass={styles.input4}
              enableAreaCodes={false}
              onlyCountries={['us']}
              country={'us'}
              value={phone}
              placeholder="000-000-0000"
              onChange={handlePhoneInputChange}
            />
          </div>
          <div className={styles.nameframe}>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="Address Line1"
                placeholder="Address Line 1"
                size="medium"
                margin="none"
                {...register("addressLine1")}
              />
            </div>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="Address Line 2"
                placeholder="Address Line 2"
                size="medium"
                margin="none"
                {...register("addressLine2")}
              />
            </div>
          </div>
          <div className={styles.nameframe}>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="City"
                placeholder="City"
                size="medium"
                margin="none"
                {...register("city")}
              />
            </div>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="State"
                placeholder="State"
                size="medium"
                margin="none"
                helperText={errors.state?.message}
                error={!!errors.state?.message}
                {...register("state")}
                inputProps={{
                  style: { textTransform: "uppercase" },
                  maxLength: 2
                }}
              />
            </div>
            <div className={styles.firstnameinput}>
              <TextField
                className={styles.input}
                color="primary"
                variant="outlined"
                type="text"
                label="Zip"
                placeholder="Zip Code"
                size="medium"
                margin="none"
                helperText={errors.zip?.message}
                error={!!errors.zip?.message}
                {...register("zip")}
                inputProps={{ maxLength: 5 }}
              />
            </div>
          </div>
          <div className={styles.nameframe}>
            <FormControlLabel
              label={<Typography sx={{ fontSize: 24 }}>Is PFPA Member ($50 discount)</Typography>}
              labelPlacement="end"
              control={<Checkbox checked={pFPAMemberChecked} color="primary" size="medium" onChange={handlePFPACheckboxChange} />}
            />
          </div>
          <div className={styles.paymentframe}>
            <div className={styles.paymentMethod1}>Payment Method 1: PayPal, Debt or Credit Card</div>
            {paymentComplete ? (
              <div className={styles.pleaseFillThe}>Payment Received! Confirmation code:<b> {confirmationId}</b></div>
            ) :
              <div className={styles.paymentamount}>
                <div className={styles.yourPaymentOfContainer}>
                  <span className={styles.yourPayment}>{`Summer Camp Eearly Bird Tuition `}</span>
                  <b className={styles.yourPayment}>${price}</b>

                </div>
              </div>
            }

            {show ? (
              <div className={styles.paypalMain}>
                <PayPalButtons
                  style={{ layout: "vertical", height: 55 }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                  key={price}
                  amount={price}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.paymentzelleframe}>
            <div
              className={styles.paymentMethod1}
            >{`Payment Method 2: Zelle `}</div>
            <div className={styles.paymentamount}>
              <img
                className={styles.zelleLogoSmall1Icon}
                alt=""
                src="/zelle-logo-small-1@2x.png"
              />
            </div>
            <div className={styles.paymentamount}>
              <div className={styles.yourPaymentOfContainer}>
                <span className={styles.yourPayment}>{`Summer Camp Eearly Bird Tuition `}</span>
                <b className={styles.yourPayment}>${price}</b>

              </div>
            </div>
            <div className={styles.zelleinstruction}>
              <div className={styles.zelleconfirmation1}>
                <div className={styles.recipientNamePacific}>
                  Recipient Name: Pacific Financial Professional Association
                </div>
              </div>
              <div className={styles.zelleacct}>
                <div className={styles.recipientNamePacific}>
                  Zelle: info@PFPA-financial.com
                </div>
              </div>
              <div className={styles.zelleacct}>
                <div className={styles.recipientNamePacific}>
                  Add Message: Student Name
                </div>
              </div>
            </div>
            <div className={styles.zelleconfirmation1}>
              <div
                className={styles.step1Please}
              >{`Step 1: Please Complete Zelle Payment `}</div>
            </div>
            <div className={styles.zelleconfirmation1}>
              <div className={styles.step1Please}>
                Step 2: Provide Zelle Payment Confirmation Code
              </div>
            </div>
            <div className={styles.parentnameframe}>
              <div className={styles.firstnameinput}>
                <TextField
                  className={styles.input}
                  color="primary"
                  variant="outlined"
                  type="text"
                  label="Zelle Confirmation Code"
                  placeholder="Zelle Confirmation Code"
                  size="medium"
                  margin="none"

                  {...register("zelleConfirmationCode")}
                />
              </div>
            </div>
          </div>

          <div className={styles.disclaimerframe}>
            <div className={styles.iHerebyGiveContainer}>
              <p className={styles.iHerebyGive}>
                I hereby give my approval for my child's participation in any and
                all activities prepared by during the summer camp. In exchange for
                the acceptance of said child's candidacy by PFPA Sumer Camp, I
                assume all risk and hazards incidental to the conduct of the
                activities, and release, absolve and hold harmless . and all its
                respective officers, agents, and representatives from any and all
                liability for injuries to said child arising out of traveling to,
                participating in, or returning from camp sessions.
              </p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                In case of injury to said child, I hereby waive all claims against
                PFPA. including all speakers and affiliates, all participants,
                sponsoring agencies, advertisers, and, if applicable, owners and
                lessors of premises used to conduct the event. There is a risk of
                being injured that is inherent in all sports activities, including
                basketball. Some of these injuries include, but are not limited
                to, the risk of fractures, paralysis, or death.
              </p>
              <p className={styles.iHerebyGive}></p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                I hereby agree and declare that I am the legal parentguardian of
                the above-named child and hereby consent to the child's
                participation in the activities that are described to me in
                registration process. I understand that activities of the kind
                described may result in physical injury to my child but
                nonetheless specifically request that he or she be allowed to
                participate in those activities.
              </p>
              <p className={styles.iHerebyGive}></p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                If the above-named child requires any emergency medical treatment
                or procedures during the activities, I hereby consent to activity
                supervisor(s) to make any decision and take any action to arrange
                for such procedures or treatments in the discretion of the
                activity supervisor(s).
              </p>
              <p className={styles.iHerebyGive}></p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                I, the parent/guardian, hereby agree and declare that I have
                carefully read and understand the scope of the summer camp
                activities and I consent to the participation of the above-named
                child to these activities.
              </p>
              <p className={styles.iHerebyGive}></p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                I hereby give my approval for my participation in any and all activities prepared by during the summer camp. In exchange for the acceptance of my candidacy by PFPA Sumer Camp, I assume all risk and hazards incidental to the conduct of the activities, and release, absolve and hold harmless. and all its respective officers, agents, and representatives from any and all liability for injuries to me arising out of traveling to, participating in, or returning from camp sessions.
              </p>
              <p className={styles.iHerebyGive}></p>
              <p className={styles.iHerebyGive}>&nbsp;</p>
              <p className={styles.iHerebyGive}>
                In case of injury to me, I hereby waive all claims against PFPA. including all speakers and affiliates, all participants, sponsoring agencies, advertisers, and, if applicable, owners and lessors of premises used to conduct the event. There is a risk of being injured that is inherent in all sports activities, including basketball. Some of these injuries include, but are not limited to, the risk of fractures, paralysis, or death.
              </p>
            </div>
          </div>

          <div className={styles.iagreeframe}>
            <FormControlLabel
              label="I AGREE"
              labelPlacement="end"
              control={<Checkbox checked={checked} color="primary" size="medium" onChange={handleCheckbox1Change} />}
            />
          </div>

          <TextField
            className={styles.notesinput}
            color="primary"
            variant="outlined"
            multiline
            rows={4}
            label="Please input additional notes"
            placeholder="notes"
            margin="none"
            {...register("notes")}
          />
        </div>
        <Button type="submit" disabled variant="contained" color="info" sx={{ padding: 1, margin: 2 }}>
          Submit
        </Button>

        <div>
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={openRegistered}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              PFPA Training Courses
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your registration is confirmed. You will receive confirmation email.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </form>
    </PayPalScriptProvider>
  );
};

export default CourseRegForm;
